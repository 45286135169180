import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeaderBuero from "../../components/header_buero"

const MitarbeiterPage = ({ data }) => {
  const headerImg = data.allStrapiPartner.edges[0].node.picture.localFile.childImageSharp.fluid;
  const headerText = data.allStrapiPartner.edges[0].node.content;
  const employee = data.allStrapiEmployee.edges;

  return (
    <Layout>
      <SEO title="Mitarbeiter" />
      <HeaderBuero />
      <Img fluid={ headerImg } className="heroimage"/>
      <Reactmarkdown>{ headerText }</Reactmarkdown>

      <div className="grid-mitarbeiter">
          { employee.map(({ node }, index) => (
            <figure key={index}>
              <div>
                <Link to={`/buero/partner/${node.slug}`}><Img fluid={ node.pictureprev.localFile.childImageSharp.fluid } style={{height: '150px'}} />
                <div className="grid-desc">{ node.name }</div>
                </Link>  
              </div>
            </figure>
          ))}   
      </div>

    </Layout>
  )
}

export default MitarbeiterPage

export const query = graphql`
  query QuerPartner {
    allStrapiPartner {
      edges {
        node {
          content
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allStrapiEmployee(filter: {partner: {eq: true}}) {
      edges {
        node {
          slug
          name
          pictureprev {
            localFile {
              childImageSharp {
                fluid(maxHeight: 470) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
